body:after {
  content: url("../../../../images/abicko/dist/non-sprite/close.png") url("../../../../images/abicko/dist/non-sprite/loading.gif") url("../../../../images/abicko/dist/non-sprite/prev.png") url("../../../../images/abicko/dist/non-sprite/next.png");
  display: none;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  z-index: 99999;
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 80);
  opacity: .8;
  background-color: #000;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.lightbox {
  z-index: 100000;
  text-align: center;
  width: 100%;
  font-weight: normal;
  line-height: 0;
  position: absolute;
  left: 0;
}

.lightbox .lb-image {
  max-width: inherit;
  border-radius: 3px;
  height: auto;
  display: block;
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  zoom: 1;
  background-color: #fff;
  border-radius: 4px;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  position: relative;
}

.lb-outerContainer:after {
  content: "";
  clear: both;
  display: table;
}

.lb-container {
  padding: 4px;
}

.lb-loader {
  text-align: center;
  width: 100%;
  height: 25%;
  line-height: 0;
  position: absolute;
  top: 43%;
  left: 0;
}

.lb-cancel {
  background: url("../../../../images/abicko/dist/non-sprite/loading.gif") no-repeat;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  display: block;
}

.lb-nav {
  z-index: 10;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.lb-container > .nav {
  left: 0;
}

.lb-nav a {
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
  outline: none;
}

.lb-prev, .lb-next {
  cursor: pointer;
  height: 100%;
  display: block;
}

.lb-nav a.lb-prev {
  float: left;
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 0);
  opacity: 0;
  background: url("../../../../images/abicko/dist/non-sprite/prev.png") 0 48% no-repeat;
  width: 34%;
  transition: opacity .6s;
  left: 0;
}

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 100);
  opacity: 1;
}

.lb-nav a.lb-next {
  float: right;
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 0);
  opacity: 0;
  background: url("../../../../images/abicko/dist/non-sprite/next.png") right 48% no-repeat;
  width: 64%;
  transition: opacity .6s;
  right: 0;
}

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 100);
  opacity: 1;
}

.lb-dataContainer {
  zoom: 1;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  width: 100%;
  margin: 0 auto;
  padding-top: 5px;
}

.lb-dataContainer:after {
  content: "";
  clear: both;
  display: table;
}

.lb-data {
  color: #ccc;
  padding: 0 4px;
}

.lb-data .lb-details {
  float: left;
  text-align: left;
  width: 85%;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-number {
  clear: left;
  color: #999;
  padding-bottom: 1em;
  font-size: 12px;
  display: block;
}

.lb-data .lb-close {
  float: right;
  text-align: right;
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 70);
  opacity: .7;
  background: url("../../../../images/abicko/dist/non-sprite/close.png") 100% 0 no-repeat;
  outline: none;
  width: 30px;
  height: 30px;
  transition: opacity .2s;
  display: block;
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform. Microsoft. Alpha(Opacity= 100);
  opacity: 1;
}

.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  z-index: 1;
  width: 100%;
  display: none;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-touch-callout: none;
  min-height: 1px;
}

.owl-carousel .owl-item img {
  width: 100%;
  display: block;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  display: none;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: transform .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

.picker {
  text-align: left;
  color: #000;
  z-index: 10000;
  -webkit-user-select: none;
  user-select: none;
  font-size: 16px;
  line-height: 1.2;
  position: absolute;
}

.picker__input {
  cursor: default;
}

.picker__input.picker__input--active {
  border-color: #0089ec;
}

.picker__holder {
  width: 100%;
  overflow-y: auto;
}

.picker {
  width: 100%;
}

.picker__holder {
  box-sizing: border-box;
  filter: alpha(opacity= 0);
  opacity: 0;
  background: #fff;
  border: 0 solid #aaa;
  border-width: 0 1px;
  border-radius: 0 0 5px 5px;
  min-width: 176px;
  max-width: 466px;
  max-height: 0;
  transition: transform .15s ease-out, opacity .15s ease-out, max-height 0s .15s, border-width 0s .15s;
  position: absolute;
  transform: translateY(-1em)perspective(600px)rotateX(10deg);
}

.picker__frame {
  padding: 1px;
}

.picker__wrap {
  margin: -1px;
}

.picker--opened .picker__holder {
  filter: alpha(opacity= 100);
  opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  max-height: 25em;
  transition: transform .15s ease-out, opacity .15s ease-out, max-height, border-width;
  transform: translateY(0)perspective(600px)rotateX(0);
  box-shadow: 0 6px 18px 1px rgba(0, 0, 0, .12);
}

.picker__box {
  padding: 0 1em;
}

.picker__header {
  text-align: center;
  margin-top: .75em;
  position: relative;
}

.picker__month, .picker__year {
  margin-left: .25em;
  margin-right: .25em;
  font-weight: 500;
  display: inline-block;
}

.picker__year {
  color: #999;
  font-size: .8em;
  font-style: italic;
}

.picker__select--month, .picker__select--year {
  border: 1px solid #b7b7b7;
  height: 2em;
  margin-left: .25em;
  margin-right: .25em;
  padding: .5em;
}

@media (min-width: 24.5em) {
  .picker__select--month, .picker__select--year {
    margin-top: -.5em;
  }
}

.picker__select--month {
  width: 35%;
}

.picker__select--year {
  width: 22.5%;
}

.picker__select--month:focus, .picker__select--year:focus {
  border-color: #0089ec;
}

.picker__nav--prev, .picker__nav--next {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: .5em 1.25em;
  position: absolute;
  top: -.25em;
}

@media (min-width: 24.5em) {
  .picker__nav--prev, .picker__nav--next {
    top: -.33em;
  }
}

.picker__nav--prev {
  padding-right: 1.25em;
  left: -1em;
}

@media (min-width: 24.5em) {
  .picker__nav--prev {
    padding-right: 1.5em;
  }
}

.picker__nav--next {
  padding-left: 1.25em;
  right: -1em;
}

@media (min-width: 24.5em) {
  .picker__nav--next {
    padding-left: 1.5em;
  }
}

.picker__nav--prev:before, .picker__nav--next:before {
  content: " ";
  border-top: .5em solid rgba(0, 0, 0, 0);
  border-bottom: .5em solid rgba(0, 0, 0, 0);
  border-right: .75em solid #000;
  width: 0;
  height: 0;
  margin: 0 auto;
  display: block;
}

.picker__nav--next:before {
  border-left: .75em solid #000;
  border-right: 0;
}

.picker__nav--prev:hover, .picker__nav--next:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__nav--disabled, .picker__nav--disabled:hover, .picker__nav--disabled:before, .picker__nav--disabled:before:hover {
  cursor: default;
  background: none;
  border-left-color: #f5f5f5;
  border-right-color: #f5f5f5;
}

.picker__table {
  text-align: center;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  font-size: inherit;
  width: 100%;
  margin-top: .75em;
  margin-bottom: .5em;
}

@media (min-height: 33.875em) {
  .picker__table {
    margin-bottom: .75em;
  }
}

.picker__table td {
  margin: 0;
  padding: 0;
}

.picker__weekday {
  color: #999;
  width: 14.2857%;
  padding-bottom: .25em;
  font-size: .75em;
  font-weight: 500;
}

@media (min-height: 33.875em) {
  .picker__weekday {
    padding-bottom: .5em;
  }
}

.picker__day {
  border: 1px solid rgba(0, 0, 0, 0);
  padding: .3125em 0;
  font-weight: 200;
}

.picker__day--today {
  position: relative;
}

.picker__day--today:before {
  content: " ";
  border-top: .5em solid #0059bc;
  border-left: .5em solid rgba(0, 0, 0, 0);
  width: 0;
  height: 0;
  position: absolute;
  top: 2px;
  right: 2px;
}

.picker__day--disabled:before {
  border-top-color: #aaa;
}

.picker__day--outfocus {
  color: #ddd;
}

.picker__day--infocus:hover, .picker__day--outfocus:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__day--highlighted {
  border-color: #0089ec;
}

.picker__day--highlighted:hover, .picker--focused .picker__day--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__day--selected, .picker__day--selected:hover, .picker--focused .picker__day--selected {
  color: #fff;
  background: #0089ec;
}

.picker__day--disabled, .picker__day--disabled:hover, .picker--focused .picker__day--disabled {
  color: #ddd;
  cursor: default;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.picker__day--highlighted.picker__day--disabled, .picker__day--highlighted.picker__day--disabled:hover {
  background: #bbb;
}

.picker__footer {
  text-align: center;
}

.picker__button--today, .picker__button--clear, .picker__button--close {
  vertical-align: bottom;
  background: #fff;
  border: 1px solid #fff;
  width: 33%;
  padding: .66em 0;
  font-size: .8em;
  font-weight: bold;
  display: inline-block;
}

.picker__button--today:hover, .picker__button--clear:hover, .picker__button--close:hover {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
  border-bottom-color: #b1dcfb;
}

.picker__button--today:focus, .picker__button--clear:focus, .picker__button--close:focus {
  background: #b1dcfb;
  border-color: #0089ec;
  outline: none;
}

.picker__button--today:before, .picker__button--clear:before, .picker__button--close:before {
  height: 0;
  display: inline-block;
  position: relative;
}

.picker__button--today:before, .picker__button--clear:before {
  content: " ";
  margin-right: .45em;
}

.picker__button--today:before {
  border-top: .66em solid #0059bc;
  border-left: .66em solid rgba(0, 0, 0, 0);
  width: 0;
  top: -.05em;
}

.picker__button--clear:before {
  border-top: 3px solid #e20;
  width: .66em;
  top: -.25em;
}

.picker__button--close:before {
  content: "×";
  vertical-align: top;
  color: #777;
  margin-right: .35em;
  font-size: 1.1em;
  top: -.1em;
}

.picker__button--today[disabled], .picker__button--today[disabled]:hover {
  color: #ddd;
  cursor: default;
  background: #f5f5f5;
  border-color: #f5f5f5;
}

.picker__button--today[disabled]:before {
  border-top-color: #aaa;
}

.picker__list {
  margin: 0;
  padding: .75em 0 4.2em;
  list-style: none;
}

.picker__list-item {
  background: #fff;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin-bottom: -1px;
  padding: .75em 1.25em;
  position: relative;
}

@media (min-height: 46.75em) {
  .picker__list-item {
    padding: .5em 1em;
  }
}

.picker__list-item:hover {
  cursor: pointer;
  color: #000;
  z-index: 10;
  background: #b1dcfb;
  border-color: #0089ec;
}

.picker__list-item--highlighted {
  z-index: 10;
  border-color: #0089ec;
}

.picker__list-item--highlighted:hover, .picker--focused .picker__list-item--highlighted {
  cursor: pointer;
  color: #000;
  background: #b1dcfb;
}

.picker__list-item--selected, .picker__list-item--selected:hover, .picker--focused .picker__list-item--selected {
  color: #fff;
  z-index: 10;
  background: #0089ec;
}

.picker__list-item--disabled, .picker__list-item--disabled:hover, .picker--focused .picker__list-item--disabled {
  color: #ddd;
  cursor: default;
  z-index: auto;
  background: #f5f5f5;
  border-color: #ddd;
}

.picker--time .picker__button--clear {
  text-align: center;
  text-transform: uppercase;
  color: #666;
  background: none;
  border: 0;
  width: 80%;
  margin: 1em auto 0;
  padding: 1em 1.25em;
  font-size: .67em;
  font-weight: 500;
  display: block;
}

.picker--time .picker__button--clear:hover, .picker--time .picker__button--clear:focus {
  cursor: pointer;
  color: #fff;
  background: #e20;
  border-color: #e20;
  outline: none;
}

.picker--time .picker__button--clear:before {
  color: #666;
  font-size: 1.25em;
  font-weight: bold;
  top: -.25em;
}

.picker--time .picker__button--clear:hover:before, .picker--time .picker__button--clear:focus:before {
  color: #fff;
  border-color: #fff;
}

.picker--time {
  min-width: 256px;
  max-width: 320px;
}

.picker--time .picker__holder {
  background: #f2f2f2;
}

@media (min-height: 40.125em) {
  .picker--time .picker__holder {
    font-size: .875em;
  }
}

.picker--time .picker__box {
  padding: 0;
  position: relative;
}

.ps-container {
  touch-action: none;
  overflow: hidden !important;
}

.ps-container.ps-active-x > .ps-scrollbar-x-rail, .ps-container.ps-active-y > .ps-scrollbar-y-rail {
  background-color: rgba(0, 0, 0, 0);
  display: block;
}

.ps-container.ps-in-scrolling {
  pointer-events: none;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  opacity: .9;
  background-color: #eee;
}

.ps-container.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  opacity: .9;
  background-color: #eee;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container > .ps-scrollbar-x-rail {
  opacity: 0;
  height: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  bottom: 0;
}

.ps-container > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #aaa;
  border-radius: 6px;
  height: 6px;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  position: absolute;
  bottom: 2px;
}

.ps-container > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x, .ps-container > .ps-scrollbar-x-rail:active > .ps-scrollbar-x {
  height: 11px;
}

.ps-container > .ps-scrollbar-y-rail {
  opacity: 0;
  width: 15px;
  transition: background-color .2s linear, opacity .2s linear;
  display: none;
  position: absolute;
  right: 0;
}

.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #aaa;
  border-radius: 6px;
  width: 6px;
  transition: background-color .2s linear, height .2s linear, width .2s ease-in-out, border-radius .2s ease-in-out;
  position: absolute;
  right: 2px;
}

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y, .ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 11px;
}

.ps-container:hover.ps-in-scrolling {
  pointer-events: none;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail {
  opacity: .9;
  background-color: #eee;
}

.ps-container:hover.ps-in-scrolling.ps-x > .ps-scrollbar-x-rail > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail {
  opacity: .9;
  background-color: #eee;
}

.ps-container:hover.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-x-rail, .ps-container:hover > .ps-scrollbar-y-rail {
  opacity: .6;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover {
  opacity: .9;
  background-color: #eee;
}

.ps-container:hover > .ps-scrollbar-x-rail:hover > .ps-scrollbar-x {
  background-color: #999;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover {
  opacity: .9;
  background-color: #eee;
}

.ps-container:hover > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y {
  background-color: #999;
}

.select2-container {
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  position: relative;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  height: 28px;
  display: block;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  padding-right: 20px;
  display: block;
  overflow: hidden;
}

.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}

.select2-container[dir="rtl"] .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
  padding-right: 8px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  min-height: 32px;
  display: block;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 8px;
  display: inline-block;
  overflow: hidden;
}

.select2-container .select2-search--inline {
  float: left;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  margin-top: 5px;
  padding: 0;
  font-size: 100%;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  box-sizing: border-box;
  z-index: 1051;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  width: 100%;
  display: block;
  position: absolute;
  left: -100000px;
}

.select2-results {
  display: block;
}

.select2-results__options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.select2-results__option {
  -webkit-user-select: none;
  user-select: none;
  padding: 6px;
}

.select2-results__option[aria-selected] {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  padding: 4px;
  display: block;
}

.select2-search--dropdown .select2-search__field {
  box-sizing: border-box;
  width: 100%;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  opacity: 0;
  z-index: 99;
  filter: alpha(opacity= 0);
  background-color: #fff;
  border: 0;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 100%;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

.select2-hidden-accessible {
  clip: rect(0 0 0 0) !important;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 20px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: 4px solid rgba(0, 0, 0, 0);
  border-top: 5px solid #888;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #888;
}

.select2-container--default .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0 5px;
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  float: left;
  margin-top: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  margin-top: 5px;
  margin-right: 10px;
  font-weight: bold;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  cursor: default;
  float: left;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  margin-right: 2px;
  font-weight: bold;
  display: inline-block;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-search--inline {
  float: right;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: 1px solid #000;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  cursor: default;
  background-color: #eee;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  box-shadow: none;
  -webkit-appearance: textfield;
  background: none;
  border: none;
  outline: 0;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--default .select2-results__option[aria-disabled="true"] {
  color: #999;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: #ddd;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  color: #fff;
  background-color: #5897fb;
}

.select2-container--default .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-color: #f7f7f7;
  background-image: linear-gradient(#fff 50%, #eee 100%);
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  margin-right: 10px;
  font-weight: bold;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #ddd;
  background-image: linear-gradient(#eee 50%, #ccc 100%);
  background-repeat: repeat-x;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 20px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border: 4px solid rgba(0, 0, 0, 0);
  border-top: 5px solid #888;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -2px;
  margin-left: -4px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 4px 0 0 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 4px 5px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #888;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFFFFFFF", endColorstr= "#FFEEEEEE", GradientType= 0);
  background-image: linear-gradient(#fff 0%, #eee 50%);
  background-repeat: repeat-x;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFFFFFFF", GradientType= 0);
  background-image: linear-gradient(#eee 50%, #fff 100%);
  background-repeat: repeat-x;
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  margin: 0;
  padding: 0 5px;
  list-style: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  cursor: default;
  float: left;
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  margin-right: 2px;
  font-weight: bold;
  display: inline-block;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--classic .select2-dropdown {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--classic .select2-results__option[aria-disabled="true"] {
  color: gray;
}

.select2-container--classic .select2-results__option--highlighted[aria-selected] {
  color: #fff;
  background-color: #3875d7;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  cursor: pointer;
  background: #d6d6d6;
  border-radius: 3px;
  margin: 5px;
  padding: 4px 7px;
  font-size: 14px;
  display: inline-block;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #fff;
  background: #869791;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: .5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  width: 84%;
  margin: 10px auto 0;
}

.owl-theme .owl-dots .owl-dot {
  zoom: 1;
  display: inline-block;
}

.owl-theme .owl-dots .owl-dot span {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  background: #d6d6d6;
  border-radius: 30px;
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  transition: opacity .2s;
  display: block;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

.gallery-in-article .gallery .owl-theme .owl-dots .owl-dot span {
  display: none;
}

.select2-container--abc .select2-selection--single {
  text-align: center;
  background-color: #fff;
  border: 1px solid #e2e2ea;
  border-radius: 10px;
  outline: 0;
  height: 42px;
  font-size: 22px;
}

.select2-container--abc .select2-selection--single:focus {
  border: 1px solid #e2e2ea;
}

.select2-container--abc .select2-selection--single .select2-selection__rendered {
  color: #a7a7c2;
  font-size: 22px;
  line-height: 35px;
}

.select2-container--abc .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  margin-right: 10px;
  font-weight: bold;
}

.select2-container--abc .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--abc .select2-selection--single .select2-selection__arrow {
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
  background-color: #fff;
  border: none;
  border-left: 1px solid #fff;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 30px;
  height: 40px;
  position: absolute;
  top: 1px;
  right: 1px;
}

.select2-container--abc .select2-selection--single .select2-selection__arrow b {
  border: 8px solid rgba(0, 0, 0, 0);
  border-top: 10px solid #a7a7c2;
  border-bottom-width: 0;
  width: 0;
  height: 0;
  margin-top: -4px;
  margin-left: -8px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.select2-container--abc[dir="rtl"] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--abc[dir="rtl"] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #e2e2ea;
  border-radius: 10px 0 0 10px;
  left: 1px;
  right: auto;
}

.select2-container--abc.select2-container--open .select2-selection--single {
  border: 1px solid #e2e2ea;
  font-size: 22px;
}

.select2-container--abc.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
}

.select2-container--abc.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-width: 0 8px 10px;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #a7a7c2;
}

.select2-container--abc.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--abc.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--abc .select2-selection--multiple {
  cursor: text;
  background-color: #fff;
  border: 1px solid #a7a7c2;
  border-radius: 4px;
  outline: 0;
}

.select2-container--abc .select2-selection--multiple:focus {
  border: 1px solid #a7a7c2;
}

.select2-container--abc .select2-selection--multiple .select2-selection__rendered {
  margin: 0;
  padding: 0 5px;
  list-style: none;
}

.select2-container--abc .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--abc .select2-selection--multiple .select2-selection__choice {
  cursor: default;
  float: left;
  background-color: #a7a7c2;
  border: 1px solid #a7a7c2;
  border-radius: 4px;
  margin-top: 5px;
  margin-right: 5px;
  padding: 0 5px;
  font-size: 22px;
}

.select2-container--abc .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  margin-right: 2px;
  font-weight: bold;
  display: inline-block;
}

.select2-container--abc .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}

.select2-container--abc[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--abc[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--abc.select2-container--open .select2-selection--multiple {
  border: 1px solid #a7a7c2;
}

.select2-container--abc.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--abc.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.select2-container--abc .select2-search--dropdown .select2-search__field {
  border: 1px solid #a7a7c2;
  outline: 0;
}

.select2-container--abc .select2-search--inline .select2-search__field {
  box-shadow: none;
  outline: 0;
}

.select2-container--abc .select2-dropdown {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0);
}

.select2-container--abc .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--abc .select2-dropdown--below {
  border-top: none;
}

.select2-container--abc .select2-results > .select2-results__options {
  text-align: center;
  color: #a7a7c2;
  max-height: 200px;
  font-size: 22px;
  line-height: 35px;
  overflow-y: auto;
}

.select2-container--abc .select2-results__option[role="group"] {
  padding: 0;
}

.select2-container--abc .select2-results__option[aria-disabled="true"] {
  color: #a7a7c2;
}

.select2-container--abc .select2-results__option--highlighted[aria-selected] {
  color: #fff;
  background-color: #a7a7c2;
  font-size: 22px;
}

.select2-container--abc .select2-results__group {
  cursor: default;
  padding: 6px;
  display: block;
}

.select2-container--abc.select2-container--open .select2-dropdown {
  border-color: #e2e2ea;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media screen and (max-width: 620px) {
  .select2-container--abc .select2-selection--single {
    text-align: center;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    border-radius: 5px;
    outline: 0;
    height: 32px;
    font-size: 17px;
  }

  .select2-container--abc .select2-selection--single:focus {
    border: 1px solid #e2e2ea;
  }

  .select2-container--abc .select2-selection--single .select2-selection__rendered {
    color: #a7a7c2;
    font-size: 17px;
    line-height: 29px;
  }

  .select2-container--abc .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    margin-right: 10px;
    font-weight: bold;
  }

  .select2-container--abc .select2-selection--single .select2-selection__placeholder {
    color: #999;
  }

  .select2-container--abc .select2-selection--single .select2-selection__arrow {
    filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
    background-color: #fff;
    border: none;
    border-left: 1px solid #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 20px;
    height: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
  }

  .select2-container--abc .select2-selection--single .select2-selection__arrow b {
    border: 4px solid rgba(0, 0, 0, 0);
    border-top: 6px solid #a7a7c2;
    border-bottom-width: 0;
    width: 0;
    height: 0;
    margin-top: -3px;
    margin-left: -8px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .select2-container--abc[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
  }

  .select2-container--abc[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #e2e2ea;
    border-radius: 10px 0 0 10px;
    left: 1px;
    right: auto;
  }

  .select2-container--abc.select2-container--open .select2-selection--single {
    border: 1px solid #e2e2ea;
    font-size: 17px;
  }

  .select2-container--abc.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: none;
    border: none;
  }

  .select2-container--abc.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0 4px 6px;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #a7a7c2;
  }

  .select2-container--abc.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .select2-container--abc.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select2-container--abc .select2-selection--multiple {
    cursor: text;
    background-color: #fff;
    border: 1px solid #a7a7c2;
    border-radius: 4px;
    outline: 0;
  }

  .select2-container--abc .select2-selection--multiple:focus {
    border: 1px solid #a7a7c2;
  }

  .select2-container--abc .select2-selection--multiple .select2-selection__rendered {
    margin: 0;
    padding: 0 4px;
    list-style: none;
  }

  .select2-container--abc .select2-selection--multiple .select2-selection__clear {
    display: none;
  }

  .select2-container--abc .select2-selection--multiple .select2-selection__choice {
    cursor: default;
    float: left;
    background-color: #a7a7c2;
    border: 1px solid #a7a7c2;
    border-radius: 4px;
    margin-top: 3px;
    margin-right: 3px;
    padding: 0 3px;
    font-size: 17px;
  }

  .select2-container--abc .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    margin-right: 2px;
    font-weight: bold;
    display: inline-block;
  }

  .select2-container--abc .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
  }

  .select2-container--abc[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right;
    margin-left: 5px;
    margin-right: auto;
  }

  .select2-container--abc[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
  }

  .select2-container--abc.select2-container--open .select2-selection--multiple {
    border: 1px solid #a7a7c2;
  }

  .select2-container--abc.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .select2-container--abc.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .select2-container--abc .select2-search--dropdown .select2-search__field {
    border: 1px solid #a7a7c2;
    outline: 0;
  }

  .select2-container--abc .select2-search--inline .select2-search__field {
    box-shadow: none;
    outline: 0;
  }

  .select2-container--abc .select2-dropdown {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  .select2-container--abc .select2-dropdown--above {
    border-bottom: none;
  }

  .select2-container--abc .select2-dropdown--below {
    border-top: none;
  }

  .select2-container--abc .select2-results > .select2-results__options {
    text-align: center;
    color: #a7a7c2;
    max-height: 200px;
    font-size: 17px;
    line-height: 29px;
    overflow-y: auto;
  }

  .select2-container--abc .select2-results__option[role="group"] {
    padding: 0;
  }

  .select2-container--abc .select2-results__option[aria-disabled="true"] {
    color: #a7a7c2;
  }

  .select2-container--abc .select2-results__option--highlighted[aria-selected] {
    color: #fff;
    background-color: #a7a7c2;
    font-size: 17px;
  }

  .select2-container--abc .select2-results__group {
    cursor: default;
    padding: 6px;
    display: block;
  }

  .select2-container--abc.select2-container--open .select2-dropdown {
    border-color: #e2e2ea;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

@media screen and (max-width: 450px) {
  .mobile .select2-container--abc .select2-selection--single {
    text-align: center;
    background-color: #fff;
    border: 1px solid #e2e2ea;
    border-radius: 5px;
    outline: 0;
    height: 32px;
    font-size: 14px;
  }

  .mobile .select2-container--abc .select2-selection--single:focus {
    border: 1px solid #e2e2ea;
  }

  .mobile .select2-container--abc .select2-selection--single .select2-selection__rendered {
    color: #a7a7c2;
    font-size: 14px;
    line-height: 24px;
  }

  .mobile .select2-container--abc .select2-selection--single .select2-selection__clear {
    cursor: pointer;
    float: right;
    margin-right: 10px;
    font-weight: bold;
  }

  .mobile .select2-container--abc .select2-selection--single .select2-selection__placeholder {
    color: #999;
  }

  .mobile .select2-container--abc .select2-selection--single .select2-selection__arrow {
    filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#FFEEEEEE", endColorstr= "#FFCCCCCC", GradientType= 0);
    background-color: #fff;
    border: none;
    border-left: 1px solid #fff;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    width: 20px;
    height: 26px;
    position: absolute;
    top: 1px;
    right: 1px;
  }

  .mobile .select2-container--abc .select2-selection--single .select2-selection__arrow b {
    border: 4px solid rgba(0, 0, 0, 0);
    border-top: 6px solid #a7a7c2;
    border-bottom-width: 0;
    width: 0;
    height: 0;
    margin-top: -3px;
    margin-left: -8px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .mobile .select2-container--abc[dir="rtl"] .select2-selection--single .select2-selection__clear {
    float: left;
  }

  .mobile .select2-container--abc[dir="rtl"] .select2-selection--single .select2-selection__arrow {
    border: none;
    border-right: 1px solid #e2e2ea;
    border-radius: 10px 0 0 10px;
    left: 1px;
    right: auto;
  }

  .mobile .select2-container--abc.select2-container--open .select2-selection--single {
    border: 1px solid #e2e2ea;
    font-size: 17px;
  }

  .mobile .select2-container--abc.select2-container--open .select2-selection--single .select2-selection__arrow {
    background: none;
    border: none;
  }

  .mobile .select2-container--abc.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-width: 0 4px 6px;
    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #a7a7c2;
  }

  .mobile .select2-container--abc.select2-container--open.select2-container--above .select2-selection--single {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mobile .select2-container--abc.select2-container--open.select2-container--below .select2-selection--single {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mobile .select2-container--abc .select2-selection--multiple {
    cursor: text;
    background-color: #fff;
    border: 1px solid #a7a7c2;
    border-radius: 4px;
    outline: 0;
  }

  .mobile .select2-container--abc .select2-selection--multiple:focus {
    border: 1px solid #a7a7c2;
  }

  .mobile .select2-container--abc .select2-selection--multiple .select2-selection__rendered {
    margin: 0;
    padding: 0 4px;
    list-style: none;
  }

  .mobile .select2-container--abc .select2-selection--multiple .select2-selection__clear {
    display: none;
  }

  .mobile .select2-container--abc .select2-selection--multiple .select2-selection__choice {
    cursor: default;
    float: left;
    background-color: #a7a7c2;
    border: 1px solid #a7a7c2;
    border-radius: 4px;
    margin-top: 3px;
    margin-right: 3px;
    padding: 0 3px;
    font-size: 14px;
  }

  .mobile .select2-container--abc .select2-selection--multiple .select2-selection__choice__remove {
    color: #888;
    cursor: pointer;
    margin-right: 2px;
    font-weight: bold;
    display: inline-block;
  }

  .mobile .select2-container--abc .select2-selection--multiple .select2-selection__choice__remove:hover {
    color: #555;
  }

  .mobile .select2-container--abc[dir="rtl"] .select2-selection--multiple .select2-selection__choice {
    float: right;
    margin-left: 5px;
    margin-right: auto;
  }

  .mobile .select2-container--abc[dir="rtl"] .select2-selection--multiple .select2-selection__choice__remove {
    margin-left: 2px;
    margin-right: auto;
  }

  .mobile .select2-container--abc.select2-container--open .select2-selection--multiple {
    border: 1px solid #a7a7c2;
  }

  .mobile .select2-container--abc.select2-container--open.select2-container--above .select2-selection--multiple {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .mobile .select2-container--abc.select2-container--open.select2-container--below .select2-selection--multiple {
    border-bottom: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mobile .select2-container--abc .select2-search--dropdown .select2-search__field {
    border: 1px solid #a7a7c2;
    outline: 0;
  }

  .mobile .select2-container--abc .select2-search--inline .select2-search__field {
    box-shadow: none;
    outline: 0;
  }

  .mobile .select2-container--abc .select2-dropdown {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0);
  }

  .mobile .select2-container--abc .select2-dropdown--above {
    border-bottom: none;
  }

  .mobile .select2-container--abc .select2-dropdown--below {
    border-top: none;
  }

  .mobile .select2-container--abc .select2-results > .select2-results__options {
    text-align: center;
    color: #a7a7c2;
    max-height: 200px;
    font-size: 14px;
    line-height: 24px;
    overflow-y: auto;
  }

  .mobile .select2-container--abc .select2-results__option[role="group"] {
    padding: 0;
  }

  .mobile .select2-container--abc .select2-results__option[aria-disabled="true"] {
    color: #a7a7c2;
  }

  .mobile .select2-container--abc .select2-results__option--highlighted[aria-selected] {
    color: #fff;
    background-color: #a7a7c2;
    font-size: 14px;
  }

  .mobile .select2-container--abc .select2-results__group {
    cursor: default;
    padding: 6px;
    display: block;
  }

  .mobile .select2-container--abc.select2-container--open .select2-dropdown {
    border-color: #e2e2ea;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

